:root {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

section {
  offset-anchor: 60px;
  padding: 2rem;

  &:not(#start) > *:first-child {
    padding-top: 60px;

  }
}

body {
  background: url("/img/Eventthinking-compressed-83-2000x1333_bw.jpg") fixed center center;
  background-size: cover;
}
h1{
  color: #54b25a;
  margin-bottom: .75em;
}
h3{
  font-weight: bold;
}
li{
  list-style: disc;
  list-style-type: disc;
  &.nav-item{
    list-style: none;
  }
}
.bg-white{
  background: white;
}

.bg-green{
  background: #54b25a;
}
.bg-trans{
  background: rgba(0,0,0,.8);
  color: white;
  backdrop-filter: blur(.1);
}

#main-wrap{
  background: transparent;
}

#start{
  margin-top: 40vh;
}
#avatar-et-ck{
  background-image: none;

}

.subheader{
  color: #54b25a;
}

.cite{
  margin: 6rem 0;
  &:first-child{
    margin-top: 0;
  }
}

.team{
  border-bottom: none;

}
.img-grid {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;

  img {
    width: 100%;
    height: auto;
    object-fit: contain;
    padding: 1rem;
    align-self: center;
  }
}


.img-float-left{
  padding-bottom: 20px;
  width: 100%;
  height: auto;
}
.content-wrap{
  padding-right: 0;
  padding-left: 0;
}

.contact-box{
  max-width: 350px;
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
  >img{
    width: 100%;
    height: auto;
    object-fit: contain;
  }
}

@media (min-width: 720px) {
  .content-wrap{
    padding-left: 8%;
    padding-right: 8%;
  }
  .img-float-left{
    float: left;
    height: auto;
    padding-right: 20px;
    max-width: 500px;
  }
}